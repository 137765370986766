//NOTE:	https://preline.co/docs/dark-mode.html
const HSThemeAppearance = {
  init() {
    const defaultTheme = "default";
    const theme = localStorage.getItem("hs_theme") || defaultTheme;

    if (document.querySelector("html")?.classList.contains("dark")) return;
    this.setAppearance(theme);
  },
  _resetStylesOnLoad() {
    const $resetStyles = document.createElement("style");
    $resetStyles.innerText = `*{transition: unset !important;}`;
    $resetStyles.setAttribute("data-hs-appearance-onload-styles", "");
    document.head.appendChild($resetStyles);
    return $resetStyles;
  },
  setAppearance(theme: string, saveInStore = true, dispatchEvent = true) {
    const $resetStylesEl = this._resetStylesOnLoad();

    if (saveInStore) {
      localStorage.setItem("hs_theme", theme);
    }

    if (theme === "auto") {
      theme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "default";
    }

    const removeClass = ["dark", "default", "auto"];
    removeClass.forEach((r) => {
      document.querySelector("html")?.classList.remove(r);
    });
    document.querySelector("html")?.classList.add(this.getOriginalAppearance());

    setTimeout(() => {
      $resetStylesEl.remove();
    });

    if (dispatchEvent) {
      window.dispatchEvent(
        new CustomEvent("on-hs-appearance-change", { detail: theme }),
      );
    }
  },
  getAppearance() {
    let theme = this.getOriginalAppearance();
    if (theme === "auto") {
      theme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "default";
    }
    return theme;
  },
  getOriginalAppearance() {
    const defaultTheme = "default";
    return localStorage.getItem("hs_theme") || defaultTheme;
  },
};

export default function darkMode() {
  HSThemeAppearance.init();

  const $clickableThemes = document.querySelectorAll(
    "[data-hs-theme-click-value]",
  );

  $clickableThemes.forEach(($item) => {
    $item.addEventListener("click", () => {
      const theme = $item.getAttribute("data-hs-theme-click-value");
      theme && HSThemeAppearance.setAppearance(theme);
    });
  });
}
