import "./bootstrap";
import "../css/app.css";

import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { IStaticMethods } from "preline/preline";
import darkMode from "./darkMode";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}
createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => {
    import("preline/preline");

    setTimeout(() => {
      window.HSStaticMethods.autoInit();
      darkMode();
    }, 100);

    console.log("v1");

    return resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob("./Pages/**/*.tsx"),
    );
  },
  setup({ el, App, props }) {
    const root = createRoot(el);

    root.render(
      <div className="overflow-hidden">
        <App {...props} />
      </div>,
    );
  },
  progress: {
    color: "#4B5563",
  },
});
